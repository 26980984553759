import Vue from 'vue'
import Vuex from 'vuex'
//import lodash from 'lodash'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict:process.env.NODE_ENV !== 'production',

  state:{
    load: false,
  },
  getters:{
    load: function(state){
      return state.load;
    },
  },
  mutations:{
    load: function(state, payload){
      state.load = payload;
    },
  }
});