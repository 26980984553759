<template>
  <v-container fluid style="height:100%; background:linear-gradient(rgb(255, 255, 255), rgb(245, 249, 250));">
    <v-row align="center" justify="center" style="height:100%;">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="px-5 py-10" outlined>
          <v-form ref="form">
            <v-row align="center">
              <v-col>
              <v-img src="@/assets/images/hl_logo.png" width="150" style="left:50%; transform: translate(-50%);"></v-img>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="pb-0">
                <div style="color:#acacac; font-size:20px; text-align: center;">
                  <p>DTC 유전자검사 결과 확인</p>
                </div>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col>
                <div style="color:#acacac; font-size:15px; text-align: center;">
                  <p>바코드 번호(ID) 및 이름을 입력해 주세요.</p>
                </div>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="pb-0">
              <v-text-field v-model="patient_id" :autofocus="true" dense outlined placeholder="바코드번호(ID)" :rules="[required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="py-0">
              <v-text-field v-model="patient_name" @keydown.enter="login" dense outlined placeholder="이름" :rules="[required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="pt-0"><picture>
                <source media="(min-width: )" srcset="">
                <img src="" alt="">
              </picture>
              <v-btn @click="login" dark block dense :elevation="0">로그인</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import http from '@/mixin/http'
import validation from '@/mixin/validation'
export default {
  name: 'login-vue',
  mixins: [validation, http],
  data: () => ({
    patient_id: null,
    patient_name: null,
  }),
  created:function(){
    if ( this.$session.exists() ){
      this.$session.destroy();
    }
  },
  methods:{
    login: async function(){
      const valid = this.$refs.form.validate();
      if ( !valid ) return;

      let data = {
        patient_id: this.patient_id,
        patient_name: this.patient_name
      };
      let res = await this.post(this.$lookupUrl + '/server/login.php', data);
      if ( res ){
        if ( res.data.ret == '0000' ){
          //로그인성공
          this.$session.set('jwt', res.data.jwt);
          this.$router.replace({name:"Progress", query:{patient_id: encodeURI(this.patient_id), patient_name: encodeURI(this.patient_name)}});
        }
      }
    }
  }
}
</script>
