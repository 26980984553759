<template>
  <v-app>
    <v-app-bar app dense dark :elevation="0" clipped-left>
      <v-row align="center">
        <v-col cols="auto">
          <v-img src="@/assets/images/hl_logo_white.png" width="100"></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="$session.has('jwt')" cols="auto">
          <v-btn @click="logout">로그아웃</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view :key="$router.fullPath"></router-view>
      <v-overlay :absolute="false" :z-index="500" :opacity="0" :value="$store.getters.load">
        <v-progress-circular
          color="primary"
          indeterminate
          size="100"
          width="10">
        </v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export default {
  name: 'App',
  data: () => ({
    interval: null,
    click_event: new Date().getTime(),
  }),
  created:async function(){
    window.addEventListener('click', function(){
      this.click_event = new Date().getTime();
    }.bind(this));

    this.interval = setInterval(async () => {
      if ( this.$session.has('jwt') ){
        if ( new Date().getTime() > this.click_event+3600000 ){ //60분간 클릭 이벤트가 발생하지 않은 경우
          this.$session.destroy();
          this.$router.replace({name: "Login"});
          alert("일정시간 서비스 미사용으로 자동 로그아웃 되었습니다.");
          return;
        }
        let res = await axios({
          method:'get',
          //url:this.$rootUrl+'/server/common/reissuance_token.php',
          url:this.$lookupUrl+'/server/common/reissuance_token.php',
          headers:{
            jwt: this.$session.get('jwt')
          }
        }).catch(err=>{
          alert(err.message);
          this.$session.destroy();
          this.$router.replace({name: "Login"});
          return;
        });
        if ( res.status == 200 ){
          if ( res.data.ret == '0000' ){
            this.$session.set('jwt', res.data.jwt);
          }
          else{
            this.$session.destroy();
            this.$router.replace({name: "Login"});
            alert("일정시간 서비스 미사용으로 자동 로그아웃 되었습니다.");
            return;
          }
        }
        else{
          this.$session.destroy();
          this.$router.replace({name: "Login"});
        }
      }
    }, 1000*60*20); //20분 마다 refresh token
  },
  beforeDestroy:function(){
    window.removeEventListener('click', function(){
      this.click_event = null;
    });
    if ( this.interval ){
      clearInterval(this.interval);
    }
  },
  methods:{
    logout: function(){
      let con = confirm('로그아웃 하시겠습니까?');
      if ( !con ) return;
      this.$session.destroy();
      this.$router.replace({name: "Login"});
    }
  },
};
</script>

<style>
@import '@/assets/layout.css';
@import '@/assets/content.css';
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #e0e0e0;
  border-bottom: 5px solid #fe9616;
  animation: spin 1s linear infinite;
  position: relative;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style lang="scss"> // 모바일 환경 중앙 배치
@media (pointer:coarse){
  #app {
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
    .v-application--wrap {
      min-height: calc(var(--vh, 1vh) * 100) !important;
    }
  }
}
</style>